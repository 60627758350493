import { createInertiaApp } from '@inertiajs/vue3'
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {createApp, h} from 'vue';
import {ZiggyVue} from '../../vendor/tightenco/ziggy/dist/vue.m';
import '../css/app.css';
import './bootstrap';
import 'v-calendar/dist/style.css';
import dayjs from "dayjs";
import pl from 'dayjs/locale/pl'
import duration from "dayjs/plugin/duration";

import relativeTime from "dayjs/plugin/relativeTime";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';
dayjs.locale(pl);
dayjs.extend(duration);
dayjs.extend(relativeTime);

createInertiaApp({
  progress: {
    color: '#5850ec',
    showSpinner: false,
    delay: 250,
  },
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./${name}.vue`, import.meta.glob('./**/*.vue')),
  setup({el, App, props, plugin}) {
    return createApp({render: () => h(App, props)})
      .use(plugin)
      .use(ZiggyVue)
      .mount(el);
  },
}).then(r => r);
/*InertiaProgress.init({color: '#5850ec', includeCSS: true, showSpinner: false, delay: 250});*/
